body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::placeholder{
  /* color: #666666; */
  color: #a8a8a8;
  font-style: italic;
}
.placeholder-light::placeholder{
  color: #a8a8a8;
}

.font-weight-500{
  font-weight:500;
}

.cursor-pointer:hover{
  cursor: pointer;
}

.text-tiny{
  font-size: 0.7rem;
}
.text-small{
  font-size: 0.8rem;
}
.text-medium{
  font-size: 0.9rem;
}

/* p,h1,h2,h3,h4,h5{
  margin:0;
} */

p {
  margin: 4px 0;
}

:root{
  --black3: #333333;
  --black6: #666666;
  
  --pri: #057AF0;
  --pri-light: #057affc0;
  --pri-dark: #0461CC;
  --text-color: #212121e8;
  
  --light: #addbff8c;
  --light-gray: #e2e2e2;
  --green: #28a745;
  
}
.text-black{
  color: var(--black3);
}
.text-dark{
  color: var(--black6);
}

.filter-grayscale{
  filter: grayscale(1.0);
}
.text-medium{
  color: var(--text-color);
}
.bg-pri{
  background-color: var(--pri);
}
.bg-pri-light{
  background-color: var(--pri-light);
}
.bg-red{
  background-color: #f53a27;
}

.App .bg-pri-dark,.bg-pri-dark{
  background-color: var(--pri-dark);
}

@keyframes fade-in-animation{
  from{
    opacity:0;
    transform:translateX(40px)}
  to{
    opacity:1;
  }
}

@keyframes fade-in{
  from{
    opacity:0;
    /* transform:translateX(40px) */
  }
  to{
    opacity:1;
  }
}

@keyframes fade-left{
  0%{
    opacity:0;
    transform:translate(-40px, 40px);
  }
  40%{
    opacity: 1;
  }
  100%{
    opacity:1;
  }
}
@keyframes fade-right{
  0%{
    opacity:0;
    transform:translate(36px, 0px);
  }
  30%{
    opacity: 1;
  }
  100%{
    opacity:1;
  }
}
.opacity-20{
  opacity: 0.2;
}
.opacity-50{
  opacity: 0.5;
}
.opacity-70{
  opacity: 0.7;
}
.opacity-80{
  opacity: 0.7;
}
.opacity-90{
  opacity: 0.7;
}
.hover-dark:hover{
  background-color: #ececec;
}
.hover-outline:hover{
  background-color: #e9f5ff;
}
.hover-light:hover{
  opacity: 0.8;
}
.hover-underline:hover{
  text-decoration: underline;
}
.hover-scale:hover{
  transform: scale(1.01);
}
.hover-border:hover{
  border: 1px solid;
}
.hover-link:hover{
  text-decoration: underline;
  cursor: pointer;
}
.hover-padding:hover{
  padding: 6px 12px;
}

.fake-password-input{
  border-radius: 8px;
  display: flex;
  align-items: center;
  line-height: 2px;
  border: 2px solid #e6e6e6;
  color: lightgray;
  height: 36px;
  padding: 12px;
}

.my-link{
  color: var(--pri);
  font-weight: 500;
}
.my-link:hover{
  text-decoration: underline;
   
}

::-webkit-scrollbar{
  width: 6px;
}
::-webkit-scrollbar-thumb{
  border-radius: 6px;
  /* cursor: pointer; */
  background-color: rgb(138, 144, 155);
} 

.App p,h1,h2,h3{
  margin:0px;
  padding:0px;
}

textarea:focus,input:focus {
  border-color: var(--pri);
}
textarea:hover,input:hover {
  border-color: var(--pri-light);
}

textarea{
  border-radius: 8px;
  padding: 6px;
  border: 2px solid #e3e3e3;
  resize: none;
}
textarea.big{
  min-height: 8em;
}

.grow-column{
  display: flex;
  flex-direction: column;
  flex: 1 1;
  flex-grow: 1!important;
  overflow: auto;
}

.pointer-events-none{
  pointer-events: none;
}