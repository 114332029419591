@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap);

.App {
  text-align: center;
  height: 100vh;
  
  --message-panel-width: 236px;
}

.text-gray{
  color: #8b9aad;
}

.rounded-extra{
  border-radius: 12px;
}
.rounded-8{
  border-radius: 8px;
}
.rounded-top-8{
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.rounded-bottom-8{
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.border-thick{
  border: 2px solid #e3e3e3;
}
.border-thin{
  border: 1px solid #e3e3e3;
}

 *{
  font-family: 'Roboto', sans-serif;
 }


 button:active{
  outline: none;
}
 button:focus{
   outline: none;
 }


 .feature-card{
   border-radius: 12px;
   background-color: #057aff38;
 }

 .landing-display-1{
  font-size: 60px;
 }
 .landing-display-2{
  font-size: 48px;
 }
 .landing-page .container,.landing-page.container{
  max-width: 980px;
 }


 table{

 }
td{
  padding: 8px 16px;
  border-top: 1px solid lightgray;
}
 tr{
 }

.flex-grow-1{
  -webkit-flex: 1 1;
          flex: 1 1;
}

.d-flex:not(.overflow-auto):not(.overflow-hidden){
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.setup-dialog{
  max-width:28em;
  width:22em;
}
@media screen and (min-width:576px){
  .setup-dialog{
    width: 28em;
  }
}
@media screen and (max-width:360px){
  .setup-dialog{
    width: 18em;
  }
}


.App .autofeild{
  width:12.8em;
}
@media screen and (min-width:576px){
  .my-dialog{
    width: 25em;
  }
  .my-dialog-sm{
    width: 25em;
    min-height: 32vh;
  }
  .my-dialog-md{
    width: 30em;
    min-height: 32vh;
  }
  .my-dialog-lg{
    min-width: 34em;
    min-height: 40vh;

  }
  .my-dialog-xl{
    width: 42em;
    height: 50vh;
  }
  .feature-edit-area{
    min-width: 27em;
    min-height:52vh;
  }
  .App .autofeild{
    width:18.8em;
  }
}

button{
  background-color: #e3e3e3;
  color: #333333;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  padding: 6px 12px;
}

.notification-area{
  width: 26em;
  max-height:50vh;
  background-color: #f2f2f2;
  overflow: auto;

  padding-right: 4px;
  padding-left: 2px;
  /* box-shadow: inset 0px 0px 4px #868d94; */

}

@media screen and (max-width:572px){
  .notification-area{
    width: 90vw;
    max-height:54vh;
  }
  .notification-viewer{
    min-width:0px;
  }
}

.message-card:hover{
  background-color: #ffffff;
  opacity: 0.9;
  /* box-shadow: 0px 0px 4px #868d94; */
  border-radius: 6px;
  /* box-shadow: 0px 0px 0px 2px #f3f3f3; */
}
.message-card:active{
  opacity: 0.9;
  box-shadow: inset 0px 0px 4px #868d94;
  border-radius: 6px;
  /* box-shadow: 0px 0px 0px 2px #f3f3f3; */
}

.chat-bubble{
  -webkit-animation: fade-in 0.9s;
          animation: fade-in 0.9s;
}

.notification-container{
  top:64px;

}

.notification-card {
  padding: 16px;
  margin: 6px;
  color: #323232;
  /* border: 1px solid red; */
  border-left: 6px solid red;

  background-color: white;
  box-shadow: 0 0 18px #000000aa;
  border-radius: 8px;
  min-width: 15em;
  text-align: left;
  transition: all 0.6s;

  -webkit-animation: fade-in-animation 0.6s;

          animation: fade-in-animation 0.6s;
}
@media screen and (max-width:576px){
  .notification-container{
    top: unset;
    bottom: 48px;
    
  }
  .notification-card{
    padding: 12px;
    
  }
}

.service-tile{
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;

}

@media screen and (max-width:576px){
  .service-tile{
    grid-template-columns: 6fr 4fr 4fr;
  }
}

.pac-container {
  z-index: 2000;
}
.pac-item{
  padding: 6px;
  cursor: pointer;
}

.pac-item:hover .pac-icon{
  color:red;
}

.pac-item:hover{
  background-color: rgb(224, 240, 255);
  color:darkslategray;
}

.hover-danger{
  color: lightgray;
}
.hover-danger:hover{
  border-color: red;
  color:red;
}
input,input:hover,textarea:hover{
  outline:none;
  border-color: #e3e3e3;
}

.quote-table{
  display:grid;
  grid-template-columns: 0.6fr 2fr 0.6fr 1.2fr;
  grid-gap: 2px;
  gap: 2px;
}


input[type=text],input[type=number]{
  -webkit-appearance: none;
  appearance: none;
}

input[type=text] {   
  /* Remove First */
  -webkit-appearance: none;
  appearance: none;

}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dark-placeholder::-webkit-input-placeholder{
  color: darkslategray;
}

.dark-placeholder:-ms-input-placeholder{
  color: darkslategray;
}

.dark-placeholder::placeholder{
  color: darkslategray;
}

.grid-table-row:not(.empty-row):hover > *{
  /* filter: sepia(50%) hue-rotate(0deg);  */
  -webkit-filter: brightness(0.92);
          filter: brightness(0.92);
}
.grid-table-row > *{
  background-color: #fefefe;
}
.grid-table-row:nth-child(even) > *{
  background-color: #f5f5f5;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-input-placeholder{
  /* color: #666666; */
  color: #a8a8a8;
  font-style: italic;
}

:-ms-input-placeholder{
  /* color: #666666; */
  color: #a8a8a8;
  font-style: italic;
}

::placeholder{
  /* color: #666666; */
  color: #a8a8a8;
  font-style: italic;
}
.placeholder-light::-webkit-input-placeholder{
  color: #a8a8a8;
}
.placeholder-light:-ms-input-placeholder{
  color: #a8a8a8;
}
.placeholder-light::placeholder{
  color: #a8a8a8;
}

.font-weight-500{
  font-weight:500;
}

.cursor-pointer:hover{
  cursor: pointer;
}

.text-tiny{
  font-size: 0.7rem;
}
.text-small{
  font-size: 0.8rem;
}
.text-medium{
  font-size: 0.9rem;
}

/* p,h1,h2,h3,h4,h5{
  margin:0;
} */

p {
  margin: 4px 0;
}

:root{
  --black3: #333333;
  --black6: #666666;
  
  --pri: #057AF0;
  --pri-light: #057affc0;
  --pri-dark: #0461CC;
  --text-color: #212121e8;
  
  --light: #addbff8c;
  --light-gray: #e2e2e2;
  --green: #28a745;
  
}
.text-black{
  color: #333333;
  color: var(--black3);
}
.text-dark{
  color: #666666;
  color: var(--black6);
}

.filter-grayscale{
  -webkit-filter: grayscale(1.0);
          filter: grayscale(1.0);
}
.text-medium{
  color: #212121e8;
  color: var(--text-color);
}
.bg-pri{
  background-color: #057AF0;
  background-color: var(--pri);
}
.bg-pri-light{
  background-color: #057affc0;
  background-color: var(--pri-light);
}
.bg-red{
  background-color: #f53a27;
}

.App .bg-pri-dark,.bg-pri-dark{
  background-color: #0461CC;
  background-color: var(--pri-dark);
}

@-webkit-keyframes fade-in-animation{
  from{
    opacity:0;
    -webkit-transform:translateX(40px);
            transform:translateX(40px)}
  to{
    opacity:1;
  }
}

@keyframes fade-in-animation{
  from{
    opacity:0;
    -webkit-transform:translateX(40px);
            transform:translateX(40px)}
  to{
    opacity:1;
  }
}

@-webkit-keyframes fade-in{
  from{
    opacity:0;
    /* transform:translateX(40px) */
  }
  to{
    opacity:1;
  }
}

@keyframes fade-in{
  from{
    opacity:0;
    /* transform:translateX(40px) */
  }
  to{
    opacity:1;
  }
}

@-webkit-keyframes fade-left{
  0%{
    opacity:0;
    -webkit-transform:translate(-40px, 40px);
            transform:translate(-40px, 40px);
  }
  40%{
    opacity: 1;
  }
  100%{
    opacity:1;
  }
}

@keyframes fade-left{
  0%{
    opacity:0;
    -webkit-transform:translate(-40px, 40px);
            transform:translate(-40px, 40px);
  }
  40%{
    opacity: 1;
  }
  100%{
    opacity:1;
  }
}
@-webkit-keyframes fade-right{
  0%{
    opacity:0;
    -webkit-transform:translate(36px, 0px);
            transform:translate(36px, 0px);
  }
  30%{
    opacity: 1;
  }
  100%{
    opacity:1;
  }
}
@keyframes fade-right{
  0%{
    opacity:0;
    -webkit-transform:translate(36px, 0px);
            transform:translate(36px, 0px);
  }
  30%{
    opacity: 1;
  }
  100%{
    opacity:1;
  }
}
.opacity-20{
  opacity: 0.2;
}
.opacity-50{
  opacity: 0.5;
}
.opacity-70{
  opacity: 0.7;
}
.opacity-80{
  opacity: 0.7;
}
.opacity-90{
  opacity: 0.7;
}
.hover-dark:hover{
  background-color: #ececec;
}
.hover-outline:hover{
  background-color: #e9f5ff;
}
.hover-light:hover{
  opacity: 0.8;
}
.hover-underline:hover{
  text-decoration: underline;
}
.hover-scale:hover{
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}
.hover-border:hover{
  border: 1px solid;
}
.hover-link:hover{
  text-decoration: underline;
  cursor: pointer;
}
.hover-padding:hover{
  padding: 6px 12px;
}

.fake-password-input{
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  line-height: 2px;
  border: 2px solid #e6e6e6;
  color: lightgray;
  height: 36px;
  padding: 12px;
}

.my-link{
  color: #057AF0;
  color: var(--pri);
  font-weight: 500;
}
.my-link:hover{
  text-decoration: underline;
   
}

::-webkit-scrollbar{
  width: 6px;
}
::-webkit-scrollbar-thumb{
  border-radius: 6px;
  /* cursor: pointer; */
  background-color: rgb(138, 144, 155);
} 

.App p,h1,h2,h3{
  margin:0px;
  padding:0px;
}

textarea:focus,input:focus {
  border-color: #057AF0;
  border-color: var(--pri);
}
textarea:hover,input:hover {
  border-color: #057affc0;
  border-color: var(--pri-light);
}

textarea{
  border-radius: 8px;
  padding: 6px;
  border: 2px solid #e3e3e3;
  resize: none;
}
textarea.big{
  min-height: 8em;
}

.grow-column{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-grow: 1!important;
          flex-grow: 1!important;
  overflow: auto;
}

.pointer-events-none{
  pointer-events: none;
}
