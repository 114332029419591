
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap');

.App {
  text-align: center;
  height: 100vh;
  
  --message-panel-width: 236px;
}

.text-gray{
  color: #8b9aad;
}

.rounded-extra{
  border-radius: 12px;
}
.rounded-8{
  border-radius: 8px;
}
.rounded-top-8{
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.rounded-bottom-8{
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.border-thick{
  border: 2px solid #e3e3e3;
}
.border-thin{
  border: 1px solid #e3e3e3;
}

 *{
  font-family: 'Roboto', sans-serif;
 }


 button:active{
  outline: none;
}
 button:focus{
   outline: none;
 }


 .feature-card{
   border-radius: 12px;
   background-color: #057aff38;
 }

 .landing-display-1{
  font-size: 60px;
 }
 .landing-display-2{
  font-size: 48px;
 }
 .landing-page .container,.landing-page.container{
  max-width: 980px;
 }


 table{

 }
td{
  padding: 8px 16px;
  border-top: 1px solid lightgray;
}
 tr{
 }

.flex-grow-1{
  flex: 1 1 0%;
}

.d-flex:not(.overflow-auto):not(.overflow-hidden){
  flex-shrink: 0;
}

.setup-dialog{
  max-width:28em;
  width:22em;
}
@media screen and (min-width:576px){
  .setup-dialog{
    width: 28em;
  }
}
@media screen and (max-width:360px){
  .setup-dialog{
    width: 18em;
  }
}


.App .autofeild{
  width:12.8em;
}
@media screen and (min-width:576px){
  .my-dialog{
    width: 25em;
  }
  .my-dialog-sm{
    width: 25em;
    min-height: 32vh;
  }
  .my-dialog-md{
    width: 30em;
    min-height: 32vh;
  }
  .my-dialog-lg{
    min-width: 34em;
    min-height: 40vh;

  }
  .my-dialog-xl{
    width: 42em;
    height: 50vh;
  }
  .feature-edit-area{
    min-width: 27em;
    min-height:52vh;
  }
  .App .autofeild{
    width:18.8em;
  }
}

button{
  background-color: #e3e3e3;
  color: #333333;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  padding: 6px 12px;
}

.notification-area{
  width: 26em;
  max-height:50vh;
  background-color: #f2f2f2;
  overflow: auto;

  padding-right: 4px;
  padding-left: 2px;
  /* box-shadow: inset 0px 0px 4px #868d94; */

}

@media screen and (max-width:572px){
  .notification-area{
    width: 90vw;
    max-height:54vh;
  }
  .notification-viewer{
    min-width:0px;
  }
}

.message-card:hover{
  background-color: #ffffff;
  opacity: 0.9;
  /* box-shadow: 0px 0px 4px #868d94; */
  border-radius: 6px;
  /* box-shadow: 0px 0px 0px 2px #f3f3f3; */
}
.message-card:active{
  opacity: 0.9;
  box-shadow: inset 0px 0px 4px #868d94;
  border-radius: 6px;
  /* box-shadow: 0px 0px 0px 2px #f3f3f3; */
}

.chat-bubble{
  animation: fade-in 0.9s;
}

.notification-container{
  top:64px;

}

.notification-card {
  padding: 16px;
  margin: 6px;
  color: #323232;
  /* border: 1px solid red; */
  border-left: 6px solid red;

  background-color: white;
  box-shadow: 0 0 18px #000000aa;
  border-radius: 8px;
  min-width: 15em;
  text-align: left;
  transition: all 0.6s;

  animation: fade-in-animation 0.6s;
}
@media screen and (max-width:576px){
  .notification-container{
    top: unset;
    bottom: 48px;
    
  }
  .notification-card{
    padding: 12px;
    
  }
}

.service-tile{
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;

}

@media screen and (max-width:576px){
  .service-tile{
    grid-template-columns: 6fr 4fr 4fr;
  }
}

.pac-container {
  z-index: 2000;
}
.pac-item{
  padding: 6px;
  cursor: pointer;
}

.pac-item:hover .pac-icon{
  color:red;
}

.pac-item:hover{
  background-color: rgb(224, 240, 255);
  color:darkslategray;
}

.hover-danger{
  color: lightgray;
}
.hover-danger:hover{
  border-color: red;
  color:red;
}
input,input:hover,textarea:hover{
  outline:none;
  border-color: #e3e3e3;
}

.quote-table{
  display:grid;
  grid-template-columns: 0.6fr 2fr 0.6fr 1.2fr;
  gap: 2px;
}


input[type=text],input[type=number]{
  -webkit-appearance: none;
  appearance: none;
}

input[type=text] {   
  /* Remove First */
  -webkit-appearance: none;
  appearance: none;

}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dark-placeholder::placeholder{
  color: darkslategray;
}

.grid-table-row:not(.empty-row):hover > *{
  /* filter: sepia(50%) hue-rotate(0deg);  */
  filter: brightness(0.92);
}
.grid-table-row > *{
  background-color: #fefefe;
}
.grid-table-row:nth-child(even) > *{
  background-color: #f5f5f5;
}